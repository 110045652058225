import { useEffect } from 'react'
import tw from 'twin.macro'

import Person from '@components/Person'
import FlexSection from '@components/FlexSection'

import { componentLoaded, findColors } from '@utils/functions'

import {
  WpPage_Acfpagefields_Content_Team,
  WpPrograma_Acfpagefields_Content_Team
} from '@_types/graphql-types'

type contentType =
  | WpPage_Acfpagefields_Content_Team
  | WpPrograma_Acfpagefields_Content_Team

const Team = ({
  data,
  index,
  tabs
}: {
  data: contentType
  index: string
  tabs?: boolean
}) => {
  const { title, bgColor, description, teamMembers, tagTitle } = data

  const sectionColors = findColors(bgColor)

  useEffect(() => {
    componentLoaded(title)
  }, [])

  return (
    <FlexSection
      index={index}
      title={title ? title : false}
      tagTitle={tagTitle}
      colors={sectionColors}
      alignTitle="center"
      id={title || ''}
      paddingBottom={tabs}
      paddingTop={tabs}
      tabs={tabs}
    >
      <div>
        <div tw="prose !max-w-4xl !w-full mx-auto mb-10 lg:mb-20">
          <p tw="text-white text-center">{description}</p>
        </div>

        {/* render list of team members */}
        <div tw="flex flex-wrap md:grid grid-cols-4 lg:gap-x-6 lg:gap-y-16">
          {teamMembers.map((member, index) => (
            <div key={index} tw="w-full md:w-auto mb-8 lg:mb-0">
              <Person person={member} />
            </div>
          ))}
        </div>
      </div>
    </FlexSection>
  )
}

export default Team
