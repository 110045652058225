import tw from 'twin.macro'
import { useEffect, useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import { WpPage_Acfpagefields_Content_Team_TeamMembers } from '@_types/graphql-types'

type Person = WpPage_Acfpagefields_Content_Team_TeamMembers

const Person = ({ person }: { person: Person }) => {
  const [showDescription, setShowDescription] = useState(false)
  const [shouldClamp, setShouldClamp] = useState(
    person.description && person.description.length >= 200
  )

  useEffect(() => {
    if (
      !person.description ||
      (person.description && person.description.length < 200)
    ) {
      setShowDescription(true)
    }
  }, [])

  return (
    <div tw="flex lg:block">
      {person.photo && (
        <div tw="w-24 lg:w-auto">
          <GatsbyImage
            image={person.photo.localFile.childImageSharp['thumb']}
            alt={person.photo.altText || person.name}
            tw="rounded-tl-2xl rounded-br-2xl mr-4 lg:mr-0 lg:(mb-6 rounded-tl-[120px] rounded-br-[120px])"
          />
        </div>
      )}
      <div tw="lg:mt-2 flex-1">
        <p tw="text-white font-medium lg:text-xl">{person.name}</p>
        <p tw="text-lemann-dark-orange font-medium lg:mb-3">{person.role}</p>
        <p tw="text-white text-sm" css={[!showDescription && tw`line-clamp-4`]}>
          {person.description}
        </p>
        {shouldClamp && (
          <div>
            {showDescription ? (
              <small
                tw="text-lemann-orange cursor-pointer"
                onClick={() => setShowDescription(false)}
              >
                ver menos
              </small>
            ) : (
              <small
                tw="text-lemann-orange cursor-pointer"
                onClick={() => setShowDescription(true)}
              >
                ver mais
              </small>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Person
